<template>
  <div class="ng-content-wrap">
    <NgongVegBanner />
    <div class="ng-block-head ng-block-head-lg">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>Dashboard</span>
        </router-link>
      </div>
      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h2 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h2>
          <div class="ng-block-des d-print-none">
            <p>You can find all of your products</p>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li v-if="!selected.length">
              <div class="form-group">
                <input
                  type="text"
                  class="form-control d-print-none"
                  placeholder="Search Product"
                  v-model="search"
                  @keyup="searchRecords"
                />
              </div>
            </li>
            <li>
              <a
                href="#newItem"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-plus-c"></em>
                <span>
                  <span class="d-none d-sm-inline-block">
                    Add
                  </span>
                  New
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="newItem">
                <div class="modal-dialog" role="document">
                  <form class="modal-content" @submit.prevent="createRecord">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white" v-if="record.name">
                        Adding {{ record.name }}
                      </h5>
                      <h5 class="modal-title text-white" v-else>
                        Add New Product
                      </h5>
                    </div>

                    <div class="modal-body form row">
                      <div class="form-group col-md-12">
                        <label>Common Product Name</label>
                        <input class="form-control" v-model="record.name" />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Product Variety</label>
                        <Multiselect
                          :options="varieties"
                          v-model="record.variety_id"
                          trackBy="name"
                          label="name"
                          valueProp="id"
                          :searchable="true"
                          placeholder="Type to search..."
                        />
                      </div>

                      <div class="form-group col-md-6">
                        <label>Product Code</label>
                        <input class="form-control" v-model="record.code" />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Raw Material Costs</label>
                        <input class="form-control" v-model="record.cost" />
                      </div>
                      <div class="form-group col-md-6">
                        <label>Direct Labor Costs</label>
                        <input class="form-control" v-model="record.labor" />
                      </div>
                    </div>

                    <div class="modal-footer bg-light justify-content-end">
                      <button class="btn btn-primary float-right">
                        <em class="icon ni ni-save"></em>
                        <span>
                          Save Details
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="ng-block">
      <div class="card card-bordered loading" v-if="processing">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>

      <div class="card card-bordered" v-else>
        <div class="card-header" v-if="selected.length">
          <div class="row">
            <div class="col align-middle">
              <input
                type="text"
                class="form-control d-print-none"
                placeholder="Search Product"
                v-model="search"
                @keyup="searchRecords"
              />
            </div>
            <div class="col"></div>
            <div class="col">
              <a
                href="#"
                class="btn btn-primary btn-icon btn-round"
                @click.prevent="clearSelection"
              >
                <em class="icon ni ni-cross"></em>
              </a>
              <div class="drodown float-right">
                <a
                  href="#"
                  class="dropdown-toggle btn btn-dim btn-outline-primary"
                  data-toggle="dropdown"
                  aria-expanded="false"
                >
                  <!-- <em
								class="d-none d-sm-inline icon ni ni-calender-date"
							></em> -->
                  <span>
                    <span class="d-none d-md-inline">With</span>
                    {{ selected.length - 1 }} Selected
                  </span>
                  <em class="dd-indc icon ni ni-chevron-right"></em>
                </a>
                <div class="dropdown-menu dropdown-menu-right" style="">
                  <ul class="link-list-opt no-bdr">
                    <li>
                      <a href="#" @click.prevent="createRecord">
                        <span>
                          Do Something
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <data-table
          :headers="headers"
          :records="records.data"
          @delete="deleteRecord"
          @selected="setSelected"
        >
          <template #title="scope">
            {{ scope.record.name }}
          </template>
          <template #details="scope">
            <h1>{{ scope.record.name }}</h1>
          </template>
          <template #form="scope">
            <form class="form row" @submit.prevent="updateRecord(scope.record)">
              <div class="form-group col-md-12">
                <label>Common Product Name</label>
                <input class="form-control" v-model="scope.record.name" />
              </div>

              <div class="form-group col-md-6">
                <label>Product Variety</label>
                <Multiselect
                  :options="varieties"
                  v-model="scope.record.variety_id"
                  trackBy="name"
                  label="name"
                  valueProp="id"
                  :searchable="true"
                  placeholder="Type to search..."
                />
              </div>
              <div class="form-group col-md-6">
                <label>Product Code</label>
                <input class="form-control" v-model="scope.record.code" />
              </div>
              <div class="form-group col-md-6">
                <label>Raw Material Costs</label>
                <input class="form-control" v-model="scope.record.cost" />
              </div>
              <div class="form-group col-md-6">
                <label>Direct Labor Costs</label>
                <input class="form-control" v-model="scope.record.labor" />
              </div>
              <div class="form-group col-md-12">
                <button class="btn btn-primary float-right">
                  <em class="icon ni ni-save"></em>
                  <span> Update {{ scope.record.name }} </span>
                </button>
              </div>
            </form>
          </template>
        </data-table>
        <paginate
          :data="records"
          @changed="fetchRecords"
          v-if="records.data.length"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../../common/services/http";
import { defineComponent, ref, reactive, onMounted } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const search = ref("");
    const headers = {
      name: "Name",
      code: "Code",
      cost: "Raw Cost",
      labor: "Labor",
      variety: "Variety"
    };

    const records = ref({
      data: []
    });

    const varieties = ref([]);

    const record = reactive({
      name: "",
      code: "",
      cost: 0,
      labor: 0,
      variety_id: 0
    });

    const selected = ref([]);

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchRecords(page = 1) {
      processing.value = true;
      http.get(`/api/products?page=${page}`).then(res => {
        records.value = res.data;
        processing.value = false;
      });
    }

    function fetchVarieties() {
      processing.value = true;
      http.get(`/api/varieties?fetch=all`).then(res => {
        varieties.value = res.data;
        processing.value = false;
      });
    }

    function searchRecords() {
      //processing.value = true;
      http.get(`/api/products?s=${search.value}`).then(res => {
        records.value = res.data;
        //processing.value = false;
      });
    }

    function createRecord() {
      http
        .post("/api/products", record)
        .then(res => {
          if (res.data.success) {
            created.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideNewForm")?.click();
          fetchRecords();
        });
    }

    function updateRecord(record: any) {
      record._method = "PUT";
      http
        .post(`/api/products/${record.id}`, record)
        .then(res => {
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          document.getElementById("hideEditForm")?.click();
          fetchRecords();
        });
    }

    function deleteRecord(id: any) {
      http.post(`/api/products/${id}`, { _method: "DELETE" }).then(res => {
        fetchRecords();
      });
    }

    onMounted(() => {
      fetchRecords();
      fetchVarieties();
    });

    function setSelected(s: any) {
      selected.value = s;
    }
    function clearSelection() {
      selected.value = [];
    }
    return {
      headers,
      fetchRecords,
      processing,
      records,
      createRecord,
      created,
      record,
      updateRecord,
      updated,
      deleteRecord,
      setSelected,
      selected,
      search,
      searchRecords,
      clearSelection,
      varieties
    };
  }
});
</script>
